import firebase from "./firebase";
//globals
var firestore = firebase.firestore();
var stats = {
  seminarCounter: 0,
  seminarLimit: 0,
  m2Counter: 0,
  m2Limit: 0,
  m3Counter: 0,
  m3Limit: 0,
  m4Counter: 0,
  m4Limit: 0
};

//fetching stats
var fetchingStats = async () => {
  try {
    var statsSnap = await firestore
    .collection("info")
    .doc("stats")
    .get();
  stats = statsSnap.data();
  // console.log(stats)
  } catch (error) {
    console.log(error.message)
  }
};

//displaying stats
var displayStats = () => {
  var {
    seminarCounter,
    seminarLimit,
    m2Counter,
    m2Limit,
    m3Counter,
    m3Limit,
    m4Counter,
    m4Limit
  } = stats;
  var seminarCounterEl = document.querySelector("#seminarCounter");
  var seminarLimitEl = document.querySelector("#seminarLimit");
  var m2CounterEl = document.querySelector("#m2Counter");
  var m2LimitEl = document.querySelector("#m2Limit");
  var m3CounterEl = document.querySelector("#m3Counter");
  var m3LimitEl = document.querySelector("#m3Limit");
  seminarCounterEl.textContent =
    seminarCounter >= 0 && seminarCounter <= 9
      ? `0${seminarCounter}`
      : `${seminarCounter}`;
  seminarLimitEl.textContent = `${seminarLimit}`;

  m2CounterEl.textContent =
    m2Counter >= 0 && m2Counter <= 9 ? `0${m2Counter}` : `${m2Counter}`;
  m2LimitEl.textContent = `${m2Limit}`;

  m3CounterEl.textContent =
    m4Counter >= 0 && m4Counter <= 9 ? `0${m4Counter}` : `${m4Counter}`;
  m3LimitEl.textContent = `${m4Limit}`;
};


//initialize app
var init = async () => {
  await fetchingStats();
  displayStats();
  //setting up listeners for seminar
  var seminarRegisterationBtn = document.querySelector(
    ".seminarRegisterationBtn"
  );
  seminarRegisterationBtn.addEventListener("click", seminarHandler);
  var seminarRegisterationForm = document.querySelector(
    "#seminarRegisterationForm"
  );
  seminarRegisterationForm.addEventListener("submit", e =>
    seminarFormHandler(e)
  );
  //setting up listeners for m2
  var m2RegisterationBtn = document.querySelector(".m2RegisterationBtn");
  m2RegisterationBtn.addEventListener('click',m2Handler);
  var m2RegisterationForm = document.querySelector(
    "#m2RegisterationForm"
  );
  m2RegisterationForm.addEventListener("submit", e => m2FormHandler(e)
  );
  //setting up listeners for m3
  var m3RegisterationBtn = document.querySelector(".m3RegisterationBtn");
  m3RegisterationBtn.addEventListener('click',m3Handler);
  var m3RegisterationForm = document.querySelector(
    "#m3RegisterationForm"
  );
  m3RegisterationForm.addEventListener("submit", e => m4FormHandler(e)
  );
};
init()


//seminar section handling
var seminarHandler = () => {
  if (stats.seminarCounter < stats.seminarLimit) {
    openSeminarRegisterationForm();
  } else {
    openSorryModal();
  }
};

var seminarFormHandler = async e => {
  e.preventDefault();
  var fullName = document
    .querySelector("#seminarRegisterationFullName")
    .value.trim();
  var email = document.querySelector("#seminarRegisterationEmail").value.trim();
  var contact = document
    .querySelector("#seminarRegisterationContact")
    .value.trim();
  var university = document
    .querySelector("#seminarRegisterationUniversity")
    .value.trim();
  var user = {
    fullName,
    email,
    contact,
    university
  };
  if (fullName && email && contact && university) {
    try {
      //closing modal immediately to avoid resubmission
      closeSeminarRegisterationForm()
      //fetching stats to verify if valid registeration or not
      await fetchingStats();
      if (stats.seminarCounter < stats.seminarLimit) {
        //storing user info
        await firestore
          .collection("SEMINAR_JANUARY_2019")
          .doc(`${fullName} -- ${stats.seminarCounter}`)
          .set(user);
        //increment counter
        var increment = firebase.firestore.FieldValue.increment(1);
        await firestore
          .collection("info")
          .doc("stats")
          .update({ seminarCounter: increment });
        //open congo modal
        openCongoModal()
        //fetchig new stats and displaying it
        await fetchingStats();
        displayStats()
      } else {
        openSorryModal();
      }
    } catch (error) {
      openErrorModal()
    }
  } 
};


//m2 section handling
var m2Handler = () => {
  if (stats.m2Counter < stats.m2Limit) {
    openM2RegisterationForm();
  } else {
    openSorryModal();
  }
};

var m2FormHandler = async e => {
  e.preventDefault();
  var fullName = document
    .querySelector("#m2RegisterationFullName")
    .value.trim();
  var email = document.querySelector("#m2RegisterationEmail").value.trim();
  var contact = document
    .querySelector("#m2RegisterationContact")
    .value.trim();
  var university = document
    .querySelector("#m2RegisterationUniversity")
    .value.trim();
  var user = {
    fullName,
    email,
    contact,
    university
  };
  if (fullName && email && contact && university) {
    try {
      //closing modal immediately to avoid resubmission
      closeM2RegisterationForm()
      //fetching stats to verify if valid registeration or not
      await fetchingStats();
      if (stats.m2Counter < stats.m2Limit) {
        //storing user info
        await firestore
          .collection("M2_JANUARY_2019")
          .doc(`${fullName} -- ${stats.m2Counter}`)
          .set(user);
        //increment counter
        var increment = firebase.firestore.FieldValue.increment(1);
        await firestore
          .collection("info")
          .doc("stats")
          .update({ m2Counter: increment });
        //open congo modal
        openCongoModal()
        //fetchig new stats and displaying it
        await fetchingStats();
        displayStats()
      } else {
        openSorryModal();
      }
    } catch (error) {
      openErrorModal()
    }
  } 
};

//m3 section handling
var m3Handler = () => {
  if (stats.m4Counter < stats.m4Limit) {
    openM3RegisterationForm();
  } else {
    openSorryModal();
  }
};

var m3FormHandler = async e => {
  e.preventDefault();
  var fullName = document
    .querySelector("#m3RegisterationFullName")
    .value.trim();
  var email = document.querySelector("#m3RegisterationEmail").value.trim();
  var contact = document
    .querySelector("#m3RegisterationContact")
    .value.trim();
  var university = document
    .querySelector("#m3RegisterationUniversity")
    .value.trim();
  var user = {
    fullName,
    email,
    contact,
    university
  };
  if (fullName && email && contact && university) {
    try {
      //closing modal immediately to avoid resubmission
      closeM3RegisterationForm()
      //fetching stats to verify if valid registeration or not
      await fetchingStats();
      if (stats.m3Counter < stats.m3Limit) {
        //storing user info
        await firestore
          .collection("M3_OCTOBER_2020")
          .doc(`${fullName} -- ${stats.m3Counter}`)
          .set(user);
        //increment counter
        var increment = firebase.firestore.FieldValue.increment(1);
        await firestore
          .collection("info")
          .doc("stats")
          .update({ m3Counter: increment });
        //open congo modal
        openCongoModal()
        //fetchig new stats and displaying it
        await fetchingStats();
        displayStats()
      } else {
        openSorryModal();
      }
    } catch (error) {
      openErrorModal()
    }
  } 
};


var m4FormHandler = async e => {
  e.preventDefault();
  var fullName = document
    .querySelector("#m3RegisterationFullName")
    .value.trim();
  var email = document.querySelector("#m3RegisterationEmail").value.trim();
  var contact = document
    .querySelector("#m3RegisterationContact")
    .value.trim();
  var university = document
    .querySelector("#m3RegisterationUniversity")
    .value.trim();
  var user = {
    fullName,
    email,
    contact,
    university
  };
  if (fullName && email && contact && university) {
    try {
      //closing modal immediately to avoid resubmission
      closeM3RegisterationForm()
      // fetching stats to verify if valid registeration or not
      await fetchingStats();
      if (stats.m4Counter < stats.m4Limit) {
        //storing user info
        await firestore
          .collection("M4_MARCH_2021")
          .doc(`${fullName} -- ${stats.m4Counter}`)
          .set(user);
        //increment counter
        var increment = firebase.firestore.FieldValue.increment(1);
        await firestore
          .collection("info")
          .doc("stats")
          .update({ m4Counter: increment });
        //open congo modal
        openCongoModal()
        //fetchig new stats and displaying it
        await fetchingStats();
        displayStats()
      } else {
        openSorryModal();
      }
    } catch (error) {
      openErrorModal()
    }
  } 
};



//modal functions
var openSeminarRegisterationForm = () => {
  $(".mini.modal.seminarForm").modal("show");
};
var closeSeminarRegisterationForm = () => {
  $(".mini.modal.seminarForm").modal("hide");
};

var openM2RegisterationForm = () => {
  $(".mini.modal.m2Form").modal("show");
};
var closeM2RegisterationForm = () => {
  $(".mini.modal.m2Form").modal("hide");
};

var openM3RegisterationForm = () => {
  $(".mini.modal.m3Form").modal("show");
};
var closeM3RegisterationForm = () => {
  $(".mini.modal.m3Form").modal("hide");
};

var openSorryModal = () => {
  $(".tiny.modal.sorry").modal("show");
};

var openCongoModal = () => {
  $(".tiny.modal.congoRegister").modal("show");
}

var openErrorModal = () => {
  $(".tiny.modal.error").modal("show");
}

//code for avoiding auto generated link by fb
var fbParamAvoider = () => {
  var param = "fbclid";
  if (location.search.indexOf(param + "=") !== -1) {
    var replace = "";
    try {
      var url = new URL(location);
      url.searchParams.delete(param);
      replace = url.href;
    } catch (ex) {
      var regExp = new RegExp("[?&]" + param + "=.*$");
      replace = location.search.replace(regExp, "");
      replace = location.pathname + replace + location.hash;
    }
    history.replaceState(null, "", replace);
  }
};
fbParamAvoider();


//handling smooth scroll
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
      e.preventDefault();
      // console.log("cliccccckked")
      document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
      });
  });
});
