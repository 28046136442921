import firebase from "firebase/app";
import "firebase/firestore";
// import 'firebase/auth'
// import 'firebase/database';
// import 'firebase/storage';




const firebaseConfig = {
    apiKey: "AIzaSyC17M79Nc7J7V5eyyEEd24oZiwY_MMhCCc",
    authDomain: "webicamp-bef5c.firebaseapp.com",
    databaseURL: "https://webicamp-bef5c.firebaseio.com",
    projectId: "webicamp-bef5c",
    storageBucket: "webicamp-bef5c.appspot.com",
    messagingSenderId: "348535797964",
    appId: "1:348535797964:web:dda58404ea265de9"
  };

firebase.initializeApp(firebaseConfig);

export default firebase; 